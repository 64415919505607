import React, { useEffect, useRef, useState } from 'react'
import { music } from '../../assests/Musics/allMusic';

function PlayAudio({ gameMusic, setGameMusic, gameSound, setGameSound }) {
  const [musicStatus, setMusicStatus] = useState(
    localStorage.getItem("music")
      ? localStorage.getItem("music")
      : localStorage.setItem("music", JSON.stringify(false))
  );
  const audioRefHome = useRef(null);
  
  useEffect(() => {
    if (gameMusic === 1 || gameMusic === "1") {
      audioRefHome.current.muted = false;
      playAudioBg();
      console.log(audioRefHome.current.muted = false, "first_background_music");
    } else {
      audioRefHome.current.muted = true;
      console.log(audioRefHome.current.muted = true, "second_background_music");
    }
  }, [gameMusic]);
  useEffect(() => {
    if (gameMusic === 1 || gameMusic === "1") {
      audioRefHome.current.muted = false;
      playAudioBg();
      console.log(audioRefHome.current.muted = false, "third_background_music");

    }
  }, []);
  async function audioEnded(src) {
    if (musicStatus === "true") {
      audioRefHome.current.muted = false;
      audioRefHome.current.src = src;
      audioRefHome.current.play();
      console.log(audioRefHome.current.muted = false, "fourth_background_music");

    } else {
      audioRefHome.current.muted = true;
      console.log(audioRefHome.current.muted = true, "fifth_background_music");

    }
  }
  async function playAudioBg() {
    audioRefHome.current.src = music.Menu;
    audioRefHome.current.play();
  }
  return (
    <div>
      <audio ref={audioRefHome} onEnded={audioEnded} loop playsInline ></audio>
    </div>
  )
}

export default PlayAudio