import React, { useEffect, useState } from "react";
import style from "./OrderConfirmed.module.css";
import confirm from "../../assests/Prize on Way Page Full.png";
import { cartAction, notificationAction } from "../../actions/user";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../url";

const OrderConfirmed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();



  const userId =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));

  const [sessionId, setSessionId] = useState("");
  const [cartSuccess, setCartSUccess] = useState(false);

  function cartCheckOut(id) {
    fetch(`${baseUrl}/cart/v3/webapp/checkout `, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        response_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "True") {
          setCartSUccess(true);
        }
      });
  }

  function cartCheckOut2() {
    fetch(`${baseUrl}/cart/v3/webapp/checkout `, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "True") {
          setCartSUccess(true);
        }
      });
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");
    console.log(sessionId);

    if (sessionId) {
      cartCheckOut(sessionId);
    } else {
      cartCheckOut2();
    }
  }, [location]);


  useEffect(() => {
    dispatch(cartAction());
    dispatch(notificationAction());
    if (cartSuccess) {
      setTimeout(() => {
        navigate("/prizes");
      }, 8000);
    }
  }, [dispatch, cartSuccess]);

  return (
    <div className={style.Container}>{/* <img src={confirm} alt="" /> */}</div>
  );
};

export default OrderConfirmed;
